
import { computed, defineComponent, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { AxiosError } from 'axios';
import { ApmBase } from '@elastic/apm-rum';

/** Servicios */
import { NewService } from '@/services/general.service';
import {
  base64ToFile,
  disableEncryption,
  showErrors,
} from '@/utils/general-utils';
import { EncryptService } from '@/services/encrypt.service';
import { RequestDocumentService } from '@/services/request-document.service';

/** Interfaces */
import {
  ClosedObligationI,
  LinceObligationI,
  RepositoryObligationI,
  ResponseDownloadPdfI,
} from '@/interfaces/general.interface';
import {
  LincePseItemI,
  LincePseObligationI,
  LincePseRequestI,
} from '@/interfaces/lince.interface';
import {
  closeSwal,
  showSwalError,
  showSwalInfo,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';

/**Composables */
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

export default defineComponent({
  name: 'LastPaymentDetail',
  setup() {
    const store = useStore();
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, addTransaction, endTransactionApm } =
      useElasticTransactionApm();
    const detailItems = ref<LincePseItemI[]>([]);
    const showLoader = ref(false);
    const monthField = ref('');
    const yearField = ref('');
    const numberField = ref('');
    const documentTypeId = ref('');

    const linceObligationsId = computed<string[]>(() => {
      return store.getters.getLinceObligations.map(
        (obligations: LinceObligationI) => obligations.register_type_id
      );
    });

    const linceCloseObligationsId = computed<string[]>(() => {
      return store.getters.getCloseObligations
        .filter((item: ClosedObligationI) => {
          return typeof item.lince === 'boolean' && item.lince;
        })
        .map((item: ClosedObligationI) => item.id);
    });

    const dannObligationsId = computed<string[]>(() => {
      return store.getters.getDannObligations.map(
        (obligations: RepositoryObligationI) => obligations.register_type_id
      );
    });

    const identificationUser = computed(() => {
      return store?.state?.authUser?.identification;
    });

    const getMonths = () => {
      return {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      };
    };

    const getYears = () => {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1970 },
        (value, index) => 1971 + index
      ).sort((a, b) => b - a);
    };

    const getNumberOfRecords = () => {
      return [10, 20, 30, 40, 50];
    };

    const getParamsFilter = (download = false): LincePseRequestI => {
      const params: LincePseRequestI = {
        person_code: store.getters.getLinceId,
        year: yearField.value,
        month: monthField.value,
        obligations_id: [
          ...linceObligationsId.value,
          ...linceCloseObligationsId.value,
          ...dannObligationsId.value,
        ],
      };

      if (download) {
        params.type_id = documentTypeId.value;
      } else {
        params.identification = String(identificationUser.value);
      }

      return params;
    };

    const getFilter = async () => {
      showLoader.value = true;
      try {
        detailItems.value = [];
        customTransactioncApm(
          $apm,
          'click - filtrar-pagos',
          'custom',
          getParamsFilter()
        );
        const { data } = await new NewService().getFilterPseObligation(
          getParamsFilter()
        );
        const decrypted: LincePseObligationI = disableEncryption()
          ? data
          : EncryptService.decrypt(data);
        const attributes = decrypted?.data?.attributes;
        addTransaction({
          documentTypeId: attributes?.document_type_id,
          payments: attributes?.payments.length,
        });
        /* eslint-disable camelcase */
        const { payments, document_type_id } = decrypted?.data?.attributes;
        detailItems.value = payments;
        documentTypeId.value = document_type_id;
        showLoader.value = false;
      } catch (error) {
        showLoader.value = false;
        showErrors(
          error as AxiosError,
          'Se produjo un error al cargar los últimos pagos.'
        );
      }
      endTransactionApm();
    };

    const downloadDocument = () => {
      if (detailItems.value.length === 0) {
        showSwalInfo('¡No hay resumen de pagos para descargar!');
        return;
      }
      showSwalLoading();
      customTransactioncApm(
        $apm,
        'click - descargar-pagos',
        'custom',
        getParamsFilter(true)
      );
      new RequestDocumentService()
        .downloadPdf(getParamsFilter(true))
        .then((response) => {
          closeSwal();
          const { data } = response;
          const res: ResponseDownloadPdfI = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          addTransaction(res.data);
          const { success, message, attributes } = res.data;
          if (success) {
            const fileName = `Resumen-de-pagos-${monthField.value}-${yearField.value}.pdf`;
            const file: File = base64ToFile(
              attributes,
              fileName,
              'application/pdf'
            );
            const newBlob = new Blob([file], {
              type: 'application/pdf',
            });
            const windowUrl = window.URL || window.webkitURL;
            const url = windowUrl.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => {
              windowUrl.revokeObjectURL(url);
            }, 1000);
          } else {
            showSwalError(message);
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al descargar el resumen de pagos, por favor intentelo de nuevo.'
          );
        })
        .finally(() => endTransactionApm());
    };

    return {
      monthField,
      yearField,
      numberField,
      detailItems,
      getMonths,
      getYears,
      getNumberOfRecords,
      getFilter,
      showLoader,
      identificationUser,
      downloadDocument,
    };
  },
});
